import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = {
    googleApiKey: String,
    googleMapId: String,
    locale: String,
    searchPath: String
  };
  static targets = [
    'categoryFiltersSection',
    'categoryCheckbox',
    'subcategoryCheckbox',
    'map',
    'searchFormContainer',
    'radiusInput',
    'zipCodeInput',
    'organizationList',
    'pagination',
    'showFiltersButton'
  ];

  get categoryCheckboxes() {
    return this.categoryCheckboxTargets.map((checkboxWrapper) => {
      return checkboxWrapper.querySelector('input[type="checkbox"]');
    });
  }

  get subcategoryCheckboxes() {
    return this.subcategoryCheckboxTargets.map((checkboxWrapper) => {
      return checkboxWrapper.querySelector('input[type="checkbox"]');
    });
  }

  connect() {
    this.markers = [];
    this.loadGoogleApi();
    this.initMap();
    this.resetPage();
  }

  resetPage() {
    this.page = 1;
  }

  radiusSearchParam() {
    return `radius_in_mi=${this.radiusInputTarget.value}`;
  }

  zipCodeSearchParam() {
    return `zip_code=${this.zipCodeInputTarget.value}`;
  }

  categoryFilterParams() {
    const formData = new FormData();
    this.categoryCheckboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        formData.append('organization_category_ids[]', checkbox.value);
      }
    });

    return new URLSearchParams(formData).toString();
  }

  subcategoryFilterParams() {
    const formData = new FormData();
    this.subcategoryCheckboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        formData.append('organization_subcategory_ids[]', checkbox.value);
      }
    });

    return new URLSearchParams(formData).toString();
  }

  pageParam() {
    return `page=${this.page}`;
  }

  clearFilters() {
    this.radiusInputTarget.value = '5';
    this.zipCodeInputTarget.value = '';
    this.element
      .querySelectorAll('.tab-content.expanded')
      .forEach((expandedTab) => {
        expandedTab.classList.remove('expanded');
      });
    this.subcategoryCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.categoryCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.resetPage();
    this.searchFilter();
  }

  searchLocation(e) {
    e.preventDefault();
    this.resetPage();
    this.searchFilter();
  }

  /*
   * request search and filter params from the server, then re-render
   * map markers, list of organizations, and pagination for the list
   */
  searchFilter() {
    const filterQuery = [
      this.radiusSearchParam(),
      this.zipCodeSearchParam(),
      this.categoryFilterParams(),
      this.subcategoryFilterParams(),
      this.pageParam()
    ].join('&');

    Rails.ajax({
      url: this.searchPathValue + `?${filterQuery}`,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        this.removeMarkers();
        this.drawMarkers(data.markers);
        this.searchFormContainerTarget.innerHTML = data.search_form;
        this.organizationListTarget.innerHTML = data.organization_list;
        this.paginationTarget.innerHTML = data.pagination;
      }
    });
  }

  changePage(event) {
    event.preventDefault();
    this.page = event.params.page;
    this.searchFilter();
  }

  // initialize Google map and center on Miami
  async initMap() {
    const { Map, InfoWindow } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    this.infoWindow = new InfoWindow();
    this.AdvancedMarkerElement = AdvancedMarkerElement;

    this.map = new Map(this.mapTarget, {
      center: { lat: 25.7617, lng: -80.191788 },
      zoom: 9,
      mapId: this.googleMapIdValue
    });
    this.addCategoryListeners();
    this.addSubcategoryListeners();
  }

  // draw markers on Google map with info window pop-ups
  drawMarkers(markers) {
    markers.forEach((marker) => {
      if (
        this.map == null ||
        this.AdvancedMarkerElement == null ||
        marker.latitude == null ||
        marker.longitude == null
      ) {
        return;
      }

      const map = this.map;
      const markerElement = new this.AdvancedMarkerElement({
        map,
        position: { lat: marker.latitude, lng: marker.longitude },
        title: marker.name
      });
      markerElement.addListener('click', ({ domEvent, latLng }) => {
        const { target } = domEvent;

        this.infoWindow.close();
        this.infoWindow.setContent(
          `${markerElement.title}<br>${marker.site_address}`
        );
        this.infoWindow.open(markerElement.map, markerElement);
      });
      this.markers.push(markerElement);
    });
  }

  removeMarkers() {
    this.markers.forEach((marker) => {
      marker.map = null;
    });
  }

  addCategoryListeners() {
    this.categoryCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        this.toggleSubcategoryCheckboxes(
          checkbox.closest('.mdc-form-field'),
          checkbox
        );
        this.resetPage();
        this.searchFilter();
      });

      if (checkbox.checked) {
        this.toggleSubcategoryCheckboxes(
          checkbox.closest('.mdc-form-field'),
          checkbox
        );
        this.resetPage();
        this.searchFilter();
      }
    });
  }

  addSubcategoryListeners() {
    this.subcategoryCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', () => {
        this.resetPage();
        this.searchFilter();
      });
    });
  }

  toggleSubcategoryCheckboxes(checkboxContainer, checkbox) {
    const subcategoriesContainer = this.element.querySelector(
      `#${checkboxContainer.dataset.subcategoriesContentId}`
    );

    if (subcategoriesContainer == null) {
      return;
    }

    if (checkbox.checked) {
      subcategoriesContainer.classList.add('expanded');
    } else {
      subcategoriesContainer.classList.remove('expanded');
      this.uncheckSubcategories(subcategoriesContainer);
    }
  }

  uncheckSubcategories(subcategoriesContainer) {
    subcategoriesContainer
      .querySelectorAll('input[type="checkbox"]')
      .forEach((checkbox) => {
        checkbox.checked = false;
      });
  }

  showMap() {
    this.categoryFiltersSectionTarget.classList.add('hide');
    this.mapTarget.classList.remove('hide');
    this.mapTarget.style.display = 'block';
    this.showFiltersButtonTarget.classList.remove('hide');
    this.showFiltersButtonTarget.style.display = 'inline-flex';
  }

  showFilters() {
    this.mapTarget.classList.add('hide');
    this.categoryFiltersSectionTarget.classList.remove('hide');
    this.showFiltersButtonTarget.classList.add('hide');
  }

  // code provided by Google for loading the API
  loadGoogleApi() {
    ((g) => {
      var h,
        a,
        k,
        p = 'The Google Maps JavaScript API',
        c = 'google',
        l = 'importLibrary',
        q = '__ib__',
        m = document,
        b = window;
      b = b[c] || (b[c] = {});
      var d = b.maps || (b.maps = {}),
        r = new Set(),
        e = new URLSearchParams(),
        u = () =>
          h ||
          (h = new Promise(async (f, n) => {
            await (a = m.createElement('script'));
            e.set('libraries', [...r] + '');
            for (k in g)
              e.set(
                k.replace(/[A-Z]/g, (t) => '_' + t[0].toLowerCase()),
                g[k]
              );
            e.set('callback', c + '.maps.' + q);
            a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
            d[q] = f;
            a.onerror = () => (h = n(Error(p + ' could not load.')));
            a.nonce = m.querySelector('script[nonce]')?.nonce || '';
            m.head.append(a);
          }));
      d[l]
        ? console.warn(p + ' only loads once. Ignoring:', g)
        : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)));
    })({
      key: this.googleApiKeyValue,
      v: 'weekly',
      language: this.localeValue
    });
  }
}
