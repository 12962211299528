import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggleDescription(event) {
    let description = event.target
      .closest('.mdc-card')
      .querySelector('.event-description');
    description.classList.toggle('hide');

    let label = event.target.parentElement.querySelector('.mdc-button__label');
    if (description.classList.contains('hide')) {
      label.innerHTML = description.dataset.show;
    } else {
      label.innerHTML = description.dataset.hide;
    }
  }
}
