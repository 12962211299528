import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'commentDialog',
    'commentForm',
    'commentTextarea',
    'ratingField',
    'ratingForm',
    'deleteButton'
  ];

  get dialogController() {
    const dialogElement = this.commentDialogTarget.querySelector('.mdc-dialog');

    return this.application.getControllerForElementAndIdentifier(
      dialogElement,
      'mdc--dialog'
    );
  }

  ratingFormTargetConnected() {
    this.ratingFormTarget.addEventListener('change', () => {
      this.submitRatingForm();
    });
  }

  ratingFieldTargetConnected(ratingField) {
    ratingField
      .querySelector('label')
      .addEventListener('click', (clickedLabel) => {
        this.removeActiveLabels();
        this.addActiveLabels(ratingField);
        this.dialogController.openDialog();
        this.showDeleteIcon();
      });
  }

  commentFormTargetConnected() {
    this.commentFormTarget.addEventListener('ajax:success', () => {
      this.closeDialog();
    });
  }

  closeDialog() {
    this.commentTextareaTarget.value = '';
    this.dialogController.dialog.close();
  }

  deleteRatings() {
    Rails.ajax({
      url: this.ratingFormTarget.action,
      type: 'delete',
      success: () => {
        this.removeActiveLabels();
        this.hideDeleteButton();
      }
    });
  }

  // private

  removeActiveLabels() {
    this.ratingFieldTargets.forEach((ratingField) => {
      const icon = ratingField.querySelector('label i');
      icon.classList.remove('fas');
      icon.classList.add('far');
    });
  }

  addActiveLabels(selectedRatingField) {
    const selectedRatingValue = selectedRatingField.dataset.value;
    this.ratingFieldTargets.forEach((ratingField) => {
      if (ratingField.dataset.value <= selectedRatingValue) {
        const icon = ratingField.querySelector('label i');
        icon.classList.remove('far');
        icon.classList.add('fas');
      }
    });
  }

  submitRatingForm() {
    Rails.fire(this.ratingFormTarget, 'submit');
  }

  showDeleteIcon() {
    this.deleteButtonTarget.classList.remove('hide');
  }

  hideDeleteButton() {
    this.deleteButtonTarget.classList.add('hide');
  }
}
