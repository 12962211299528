import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggle(e) {
    let label = e.target.previousElementSibling;
    const switchLabel = e.target.parentNode.dataset;

    document
      .getElementById(e.target.parentNode.value)
      .classList.toggle('hide-on-mobile');

    if (label.innerText === switchLabel.more) {
      label.innerText = switchLabel.less;
    } else {
      label.innerText = switchLabel.more;
    }
  }
}
