import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = { pageName: String, url: String };

  connect() {
    this.element.querySelectorAll('a').forEach((a) => {
      const label = a.innerText;

      if (label == '') {
        return;
      }

      a.addEventListener('click', () => {
        Rails.ajax({
          url: `${this.urlValue}?url=${encodeURIComponent(a.href)}&label=${label}&page_name=${this.pageNameValue}`,
          type: 'post',
          dataType: 'json'
        });
      });
    });
  }
}
